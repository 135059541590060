import { keyframes } from 'styled-components';

export const BottomIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(120px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`
export const SlideInText = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-120px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

export const SlideInRight = keyframes`
  0% {
    transform-origin: right;
    transform: scaleX(0);
  }
  100% {
    transform-origin: right;
    transform: scaleX(1);
  }
`
export const SlideInLeft = keyframes`
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  100% {
    transform-origin: left;
    transform: scaleX(1);
  }
`