import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Color } from './style.js'
import styled from 'styled-components'

function SNSIconSet({ size, color }) {
    return (
        <>
            <Anchor
                href='https://youtube.com/@tomoeya-kitchenlabo'
                target="_blank" rel="noopener"
            >
                <FontAwesomeIcon
                    icon={['fab', 'youtube']}
                    color={Color[color]}
                    size={size}
                    className="youtube"
                />
            </Anchor>
            <Anchor
                href='https://instagram.com/tomoeya_1946?igshid=YmMyMTA2M2Y='
                target="_blank" rel="noopener"
            >
                <FontAwesomeIcon
                    icon={['fab', 'instagram']}
                    color={Color[color]}
                    size={size}
                    className="instagram"
                />
            </Anchor>
        </>
    )
}

const Anchor = styled.a`
    text-decoration: none;
    margin-right: 24px;
    :last-child {
        margin-right: 0;
    }
    &:hover {
        .youtube {
            color: #da1725;
        }
        .instagram {
            color:  #d93177;
        }
    }
`

export default SNSIconSet
